/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Moment from "moment";
// import lodash from "lodash";
import uniqid from "uniqid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlert2 from "react-sweetalert2";
import compress from "compress-base64";
// Axios
import axios from "axios";
import {
  getUserDataFromLocalStorage,
  Buffer,
  getTotalPremium,
  getCountries,
  canModifyQuotation,
  getBase64AndFileType,
} from "../core/utils";

import lodash from "lodash";
import CreatableSelect from "react-select/creatable";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import useAxiosPrivateMultipart from "../hooks/useAxiosPrivateMultipart";
// Components
import Navbar from "../core/components/NavBar";
import UserContext from "../core/UserContext";

const ClientKyc = () => {
  const [swalProps, setSwalProps] = useState({});
  const countries = getCountries();
  const { userData, setUserData } = useContext(UserContext);
  const userDataStorage = getUserDataFromLocalStorage();
  const axiosPrivate = useAxiosPrivate(userDataStorage);
  const axiosPrivateMultipart = useAxiosPrivateMultipart(userDataStorage);
  const [declarationsForLifeNotSaved, setDeclarationsForLifeNotSaved] =
    useState();
  const [declarationsForLife, setDeclarationsForLife] = useState();
  const [declarationQuestions, setDeclarationQuestions] = useState([]);
  const [pepQuestions, setPepQuestions] = useState([]);
  const [quotation, setQuotation] = useState();
  const [authorized, setAuthorized] = useState();
  // const [lockDeclarationQuestions, setLockDeclarationQuestions] =
  //   useState(false);
  const [fatcaQuestions, setFatcaQuestions] = useState([]);
  const [pepQuestionsJoint, setPepQuestionsJoint] = useState([]);
  const [fatcaQuestionsJoint, setFatcaQuestionsJoint] = useState([]);

  const navigate = useNavigate();
  const params = useParams();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);
  const fileNationalIdentityCardRef = useRef();
  const filePassportRef = useRef();
  const fileProveOfAddressRef = useRef();
  const [physicalDeliveryConsent, setPhysicalDeliveryConsent] = useState(false);
  const myFormRef = useRef();
  const [state, setState] = useState({
    workerVerificationConsent: false,
    fileNationalIdentityCard: null,
    fileProveOfAddress: null,
    filePassport: null,
    streetOne: "",
    streetTwo: "",
    cityTown: "",
    postCode: "",
    country: "Mauritius",
    receiveAds: null,
    maritalStatus: null,
    fileMarriageCertificate: "",
    fileBirthCertificate: "",
    proofOfAddressDate: "",
  });

  const [additionalInfo, setAdditionalInfo] = useState({
    employerName: null,
    employerAddress: null,
    exactDuties: null,
    occupationId: null,
    educationId: null,
    salaryrangeId: null,
    awardingInstitution: null,
  });

  const [occupations, setOccupations] = useState([]);
  const [occupationOptions, setOccupationOptions] = useState([]);
  const [occupationHashMap, setOccupationHashMap] = useState();
  const [salaryRanges, setSalaryRanges] = useState([]);
  const [educationalQualifications, setEducationQualifications] = useState([]);

  const [name, setName] = useState("");

  const [clientDetails, setClientDetails] = useState();

  useEffect(() => {
    const shuftiKey = process.env.REACT_APP_SHUFTI_KEY;

    getQuotationDetails();
    getDeclarationQuestions();
    getClientDetails();
    getSalaryRanges();
    getEducations();
    getOccupations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLifeName = (_quotation) => {
    if (_quotation.quotationFormData.join_loan) {
      if (_quotation?.user?.id === parseInt(params.id, 10)) {
        setName(_quotation?.user?.name);
      } else {
        setName(_quotation?.userJoint?.name);
      }
    } else {
      setName(_quotation.quotationFormData.name);
    }
  };

  const getOccupations = async () => {
    const url = process.env.REACT_APP_API_URL + `/occupation`;

    try {
      const response = await axiosPrivate.get(url);

      setOccupations(
        response.data.data.map((occupation) => ({
          id: occupation.id,
          title: occupation.title,
        }))
      );

      setOccupationOptions(
        response.data.data.map((occupation) => {
          return { value: occupation.id, label: occupation.title };
        })
      );

      setOccupationHashMap(
        response.data.data.reduce((map, occupation) => {
          map.set(occupation.id, occupation.title);
          return map;
        }, new Map())
      );
    } catch (err) {
      console.log(err);
    }
  };

  const getSalaryRanges = async () => {
    const url = process.env.REACT_APP_API_URL + `/salary-ranges`;

    try {
      const response = await axiosPrivate.get(url);
      setSalaryRanges(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getEducations = async () => {
    const url = process.env.REACT_APP_API_URL + `/education`;

    try {
      const response = await axiosPrivate.get(url);
      setEducationQualifications(response.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const saveDeclarations = async () => {
    // /user/create/declaration
    const id = params.quotationId;
    // if(declarationsForLifeNotSaved){
    const url = process.env.REACT_APP_API_URL + `/user/create/declaration`;
    const allDeclarationQuestions = [...pepQuestions, ...fatcaQuestions];
    let negativeDeclarativeResponses = 0;
    setLoading(true);

    for (let i = 0; i < allDeclarationQuestions.length; i++) {
      try {
        const payload = {
          userId: parseInt(params.id, 10),
          declarationId: allDeclarationQuestions[i].id,
          consent: allDeclarationQuestions[i].hasAccepted,
          quotationId: parseInt(id, 10),
          isMainLife: params.life === "main",
        };

        if (allDeclarationQuestions[i].hasAccepted) {
          negativeDeclarativeResponses++;
        }
        const response = await axiosPrivate.post(url, payload);

        if (response.data.success === true) {
        }
        console.log(response);
      } catch (error) {
        console.log(error);
      }
    }
    if (params.life === "main") {
      await updateQuotation({
        wantPhysicalDeliveryMainLife: physicalDeliveryConsent,
      });
    } else if (params.life === "joint") {
      await updateQuotation({
        wantPhysicalDeliveryJointLife: physicalDeliveryConsent,
      });
    }

    if (negativeDeclarativeResponses > 0) {
      sendFailedPEPVerification();
    }
  };

  const updateQuotation = async (payload) => {
    const url =
      process.env.REACT_APP_API_URL + `/quotation/${params.quotationId}`;
    // console.log("Payload----", payload);
    // debugger;

    try {
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        console.log(
          data.message
            ? data.message
            : "Quotation update was unsuccessful, please try again"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const updateQuotationStatus = async (payload) => {
    const url =
      process.env.REACT_APP_API_URL +
      `/quotation/worker/status-update/${params.quotationId}`;

    try {
      payload = { ...payload, userId: quotation?.user?.id };
      const { data } = await axiosPrivate.patch(url, payload);

      if (!data.success) {
        console.log(
          data.message
            ? data.message
            : "Quotation update was unsuccessful, please try again"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getQuotationDetails = async (fromShadowUpdate = false) => {
    const id = params.quotationId;
    const url = process.env.REACT_APP_API_URL + `/quotation/${id}`;
    const userId = userDataStorage?.data?.id;

    try {
      const response = await axiosPrivate.get(url);
      if (response.data.success === true) {
        const _quotation = response.data.data;
        _quotation.quotationFormData = JSON.parse(_quotation.quotationFormData);
        if (!canModifyQuotation(_quotation, userId)) {
          setAuthorized(false);
          return;
        } else {
          setAuthorized(true);
        }
        const totalPremium = getTotalPremium(_quotation);
        _quotation.totalPremium = totalPremium;
        // setRefund(_quotation.quotationFormData.with_refund);
        setState({
          ...state,
          payment_insurance_plan:
            _quotation.quotationFormData.payment_insurance_plan,
        });

        setQuotation(_quotation);
        getLifeName(_quotation);
      }
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  const compressBase64File = async (base64File) => {
    try {
      const compressedBase64 = await compress(base64File, {
        quality: 0.7,
        type: "image/jpeg",
      });
      return compressedBase64;
    } catch (err) {
      console.log(err);
    }
  };

  const performShuftiBackgroundCheckWithFiles = async () => {
    const base64NicFront = await getBase64(state.fileNationalIdentityCard);
    const base64NicBack = await getBase64(state.filePassport);
    const base64Address = await getBase64(state.fileProveOfAddress);

    const compressedBase64NicFront = await compressBase64File(base64NicFront);
    const compressedBase64NicBack = await compressBase64File(base64NicBack);
    const compressedBase64NicAddress = await compressBase64File(base64Address);

    let reference = `${uniqid()}-u${clientDetails.id}-q${quotation.id}`;

    const callback_url = process.env.REACT_APP_API_URL + `/kyc/callback`;

    const shuftiKey = process.env.REACT_APP_SHUFTI_KEY;

    let background_checks = {};
    if (clientDetails?.name?.split(" ").length === 2) {
      const firstName = clientDetails?.name?.split(" ")[0];
      const lastName = clientDetails?.name?.split(" ")[1];
      background_checks = {
        name: {
          first_name: firstName,
          middle_name: "",
          last_name: lastName,
        },
        dob: new Moment(clientDetails.birth).format("YYYY-MM-DD"),
      };
    } else if (clientDetails?.name?.split(" ").length > 2) {
      const firstName = clientDetails?.name?.split(" ")[0];
      const middleName = clientDetails?.name?.split(" ")[1];
      const lastName =
        clientDetails?.name?.split(" ")[
          clientDetails?.name?.split(" ").length - 1
        ];
      background_checks = {
        name: {
          first_name: firstName,
          middle_name: middleName,
          last_name: lastName,
        },
        dob: new Moment(clientDetails.birth).format("YYYY-MM-DD"),
      };
    }

    const payload = {
      reference,
      callback_url,
      email: clientDetails?.email,
      country:
        countries.find((item) => item.name === state.country).code || "GH",
      language: "EN",
      verification_mode: "any",
      background_checks,
      document: {
        proof: compressedBase64NicFront,
        additional_proof: compressedBase64NicBack,
        supported_types: ["id_card", "driving_license", "passport"],
        verification_instructions: {
          allow_paper_based: "1",
          allow_photocopy: "1",
          allow_laminated: "1",
          allow_screenshot: "1",
          allow_cropped: "1",
          allow_scanned: "1",
        },
        name: [clientDetails?.name],
        dob: new Moment(clientDetails?.birth).format("YYYY-MM-DD"),
        issue_date: "",
        expiry_date: "",
        document_number: "",
      },

      address: {
        proof: compressedBase64NicAddress,
        // supported_types: ["bank_statement", "utility_bill","birth_certificate"],
        supported_types: ["any"],
        name: [clientDetails?.name],
        full_address: "",
        address_fuzzy_match: "1",
        issue_date: "",
      },
    };

    const token = Buffer.from(shuftiKey).toString("base64");
    const url = "https://api.shuftipro.com/";
    try {
      const response = await axios.post(url, payload, {
        headers: { Authorization: `Basic ${token}` },
      });
      return console.log({ state });

      console.log(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  const submitAddressAndAdditialDetails = async () => {
    const url = process.env.REACT_APP_API_URL + `/user/update/${params.id}`;
    setLoading(true);
    const {
      streetOne,
      streetTwo,
      cityTown,
      postCode,
      country,
      maritalStatus,
      receiveAds,
    } = state;
    const {
      salaryrangeId,
      occupationId,
      educationId,
      awardingInstitution,
      employerName,
      employerAddress,
      exactDuties,
    } = additionalInfo;
    const payload = {
      streetOne,
      streetTwo,
      cityTown,
      postCode,
      country,
      receiveAds,
      maritalStatus,
      salaryrangeId: parseInt(salaryrangeId, 10),
      occupationId: occupationId,
      educationId: parseInt(educationId, 10),
      awardingInstitution,
      employerName,
      employerAddress,
      exactDuties,
    };

    try {
      const response = await axiosPrivate.patch(url, payload);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const submitFiles = async () => {
    // const url = process.env.REACT_APP_API_URL + `/kyc/worker/create`;
    if (!state.fileNationalIdentityCard || !state.fileProveOfAddress) {
      toast.error("All files are required!");
      return;
    }
    setSwalProps({
      show: true,
      showLoading: true,
      title: "Submitting KYC",
      text: "Your KYC data is being verified. Please wait a bit",
      allowEscapeKey: false,
      allowOutsideClick: true,
    });

    setLoading(true);
    const shuftiResponse = await performShuftiBackgroundCheckWithFiles();
    // console.log("🚀 ~ submitFiles ~ shuftiResponse:", shuftiResponse)

    await submitAddressAndAdditialDetails();
    toast.error(null);
    const date = state.proofOfAddressDate
      ? new Moment(state.proofOfAddressDate).format("YYYY-MM-DD")
      : state.proofOfAddressDate;
    let formData = {
      userId: params.id,
      quotationId: params.quotationId,
      fileNationalIdentityCard: state.fileNationalIdentityCard
        ? await getBase64AndFileType(state.fileNationalIdentityCard)
        : null,
      fileProveOfAddress: state.fileProveOfAddress
        ? await getBase64AndFileType(state.fileProveOfAddress)
        : null,
      proofOfAddressDate: date,
    };

    if (state.filePassport) {
      formData.filePassport = state.filePassport
        ? await getBase64AndFileType(state.filePassport)
        : null;
    }
    if (state.fileBirthCertificate) {
      formData.fileBirthCertificate = state.fileBirthCertificate
        ? await getBase64AndFileType(state.fileBirthCertificate)
        : null;
    }
    if (state.fileMarriageCertificate) {
      formData.fileMarriageCertificate = state.fileMarriageCertificate
        ? await getBase64AndFileType(state.fileMarriageCertificate)
        : null;
    }

    const filesUploadUrl =
      process.env.REACT_APP_API_URL + "/kyc-file/worker/create";

    await axiosPrivate.post(filesUploadUrl, formData);
    // if (declarationsForLife && declarationsForLifeNotSaved) {
    await saveDeclarations();
    // } else {
    //   await saveDeclarations();
    // }

    // await performShuftiAmlBackgroundChecks();

    try {
      const workerVerificationConsentResponse = await updateQuotation({
        workerVerificationConsent: state.workerVerificationConsent,
      });
      setLoading(false);
      setSwalProps({
        show: false,
        showLoading: false,
        title: "Submitting KYC",
        text: "Your KYC data is being verified. Please wait a bit",
        allowEscapeKey: false,
        allowOutsideClick: true,
      });
      // if (workerVerificationConsentResponse.data.success) {
      toast.success("Kyc verification details sent");
      myFormRef.current.reset();
      setState({
        workerVerificationConsent: false,
        fileNationalIdentityCard: null,
        fileProveOfAddress: null,
        filePassport: null,
      });
      setAdditionalInfo({
        occupationId: null,
        educationId: null,
        salaryrangeId: null,
        awardingInstitution: null,
      });

      // if (!quotation?.quotationFormData?.join_loan && !shuftiResponse) {
      //   navigate("/application-in-review");
      // } else if (
      //   quotation?.quotationFormData?.join_loan &&
      //   quotation?.userJoint?.id === parseInt(params.id, 10) &&
      //   !quotation?.user?.kycVerification
      // ) {
      //   navigate("/application-in-review");
      // }
      setTimeout(() => {
        if (quotation?.quotationFormData?.join_loan) {
          if (quotation?.user?.id === parseInt(params.id, 10)) {
            if (params.life === "main") {
              window.location.href = `${process.env.REACT_APP_BASENAME}/client-kyc/${quotation?.userJoint?.id}/${quotation.id}/joint`;
            } else if (params.life === "joint") {
              window.location.href = `${process.env.REACT_APP_BASENAME}/review/${quotation.id}`;
            }
          } else {
            window.location.href = `${process.env.REACT_APP_BASENAME}/review/${quotation.id}`;
          }
        } else {
          window.location.href = `${process.env.REACT_APP_BASENAME}/review/${quotation.id}`;
        }
      }, 2000);
      // } else {
      //   toast.error("Could not send kyc details");
      // }
    } catch (err) {
      console.log(err);
      toast.error(err.response.data.message);
      setLoading(false);
      setSwalProps({
        show: false,
        showLoading: false,
        title: "Submitting KYC",
        text: "Your KYC data is being verified. Please wait a bit",
        allowEscapeKey: false,
        allowOutsideClick: true,
      });
    }
  };
  const getDeclarationQuestions = async () => {
    const url = process.env.REACT_APP_API_URL + `/declaration/active`;

    try {
      const response = await axiosPrivate.post(url, { active: true });
      setDeclarationQuestions(response.data.data);
      setPepQuestions(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "PEP")
      );

      setFatcaQuestions(
        response.data.data
          .map((item) => {
            item.isAccepted = null;
            return item;
          })
          .filter((item) => item.type === "FATCA")
      );

      // setTimeout(() => {
      //   getDeclarationsforLife();
      // }, 1000);
    } catch (err) {
      console.log(err);
    }
  };

  const sendFailedPEPVerification = async () => {
    const url = process.env.REACT_APP_API_URL + `/utils/send-email/multiple`;

    let message = `<p>Dear ILA Underwriter</p><p>You have received a referred quotation because of a failed PEP verification on client proposal with the details;</p><p><strong>Quotation ID : ${
      quotation.id
    }</strong></p><p><strong>Temporal Policy Number: ${
      quotation.temporaryId
    }</strong></p><p><strong>Name:${name}</strong></p><p><strong>Date: ${new Moment(
      quotation.createdAt
    ).format(
      "DD/MM/YYYY"
    )}</strong></p><p>Kind Regards</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p><p>&nbsp;</p>`;
    const payload = {
      emails: ["underwriting@ila.mu"],
      subjectTitle: "Failed PEP Verification",
      messageHtml: message,
      ccEmails: [],
    };
    try {
      const response = await axiosPrivate.post(url, payload);
      if (response.data.success === true) {
        console.log("successfully sent email");
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getDeclarationsforLife = async () => {
    const url =
      process.env.REACT_APP_API_URL + `/user/declarations/${params.id}`;

    try {
      const response = await axiosPrivate.get(url);

      console.log(response.data.data.Declarations);

      setDeclarationsForLife(response.data.data.Declarations);
      setDeclarationsForLifeNotSaved(
        response.data.data.Declarations.length === 0
      );
      let _declarationQuestions = response.data.data.Declarations;
      response.data.data.Declarations.forEach((item) => {
        if (item.type === "PEP") {
          const copyOfPepQuestions = [
            ..._declarationQuestions.filter((dq) => dq.type === "PEP"),
          ];
          copyOfPepQuestions.forEach((pepQuestion) => {
            if (item.UserDeclaration.declarationId === pepQuestion.id) {
              pepQuestion.hasAccepted = null;
            }
          });
          setPepQuestions(copyOfPepQuestions);
        }
        if (item.type === "FATCA") {
          const copyOfFatcaQuestions = [
            ..._declarationQuestions.filter((dq) => dq.type === "FATCA"),
          ];
          copyOfFatcaQuestions.forEach((fatcaQuestion) => {
            if (item.UserDeclaration.declarationId === fatcaQuestion.id) {
              fatcaQuestion.hasAccepted = null;
            }
          });
          setFatcaQuestions(copyOfFatcaQuestions);
        }
      });
      if (response.data.data.Declarations.length > 0) {
        // setLockDeclarationQuestions(true);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getClientDetails = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + `/user/${params.id}`;

      const response = await axiosPrivate.get(url);

      if (response.data.success) {
        setClientDetails(response.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Navbar
        name={
          userData
            ? `${userData?.data?.firstname} ${userData?.data?.lastname}`
            : ""
        }
      />

      {authorized && (
        <div className="container">
          <h4 className="mt-4 ila-grey-text">Client KYC</h4>
          {error && <div className="alert alert-danger">{error}</div>}
          {success && <div className="alert alert-success">{success}</div>}
          {clientDetails && (
            <>
              <h4>
                {" "}
                <span className="text-muted">Kyc for</span> {clientDetails.name}
              </h4>
            </>
          )}

          <form ref={myFormRef}>
            <div className="mt-4">
              <label className="ila-form-label text-muted">
                Front of National ID Card
                <span className="text-danger ms-1">*</span>
                <small> (only images accepted)</small>
              </label>
              <input
                onChange={(event) => {
                  // get selected file
                  // console.log(event.target.files[0]);
                  const file = event.target.files[0];
                  if (file) {
                    setState({
                      ...state,
                      fileNationalIdentityCard: event.target.files[0],
                    });
                  }
                }}
                ref={fileNationalIdentityCardRef}
                type="file"
                className="form-control"
                id="fileNationalIdentityCard"
              />
            </div>
            <div className="mt-4">
              <label className="ila-form-label text-muted">
                Back of National ID Card
                <span className="text-danger ms-1">*</span>
                <small> (only images accepted)</small>
              </label>
              <input
                onChange={(event) => {
                  // get selected file
                  // console.log(event.target.files[0]);
                  const file = event.target.files[0];
                  if (file) {
                    setState({
                      ...state,
                      filePassport: event.target.files[0],
                    });
                  }
                }}
                ref={filePassportRef}
                type="file"
                className="form-control"
                id="filePassportRef"
              />
            </div>
            <div className="mt-4">
              <label className="ila-form-label text-muted">
                Proof of Address<span className="text-danger ms-1">*</span>
                <small> (only images accepted)</small>
              </label>
              <input
                onChange={(event) => {
                  // get selected file
                  // console.log(event.target.files[0]);
                  const file = event.target.files[0];
                  if (file) {
                    setState({
                      ...state,
                      fileProveOfAddress: event.target.files[0],
                    });
                  }
                }}
                ref={fileProveOfAddressRef}
                type="file"
                className="form-control"
                id="fileProveOfAddress"
              />
            </div>
            <div className={"row mt-4"}>
              <div className="col-lg-4">
                <label
                  htmlFor="fileMarriageCertificate"
                  className="col-sm-12 col-form-label ila-form-label mb-0 pb-0 text-muted"
                >
                  Marriage Certificate
                </label>
                <div className="col-sm-12">
                  <input
                    onChange={(event) => {
                      // get selected file
                      const file = event.target.files[0];
                      if (file) {
                        setState({
                          ...state,
                          fileMarriageCertificate: file,
                        });
                      }
                    }}
                    type="file"
                    className="form-control"
                    id="fileMarriageCertificate"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="fileBirthCertificate"
                  className="col-sm-12 col-form-label ila-form-label mb-0 pb-0 text-muted"
                >
                  Birth Certificate
                </label>
                <div className="col-sm-12">
                  <input
                    onChange={(event) => {
                      // get selected file
                      const file = event.target.files[0];
                      if (file) {
                        setState({
                          ...state,
                          fileBirthCertificate: file,
                        });
                      }
                    }}
                    type="file"
                    className="form-control"
                    id="fileBirthCertificate"
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <label
                  htmlFor="proofOfAddressDate"
                  className="col-sm-12 col-form-label ila-form-label mb-0 pb-0 text-muted"
                >
                  Proof Of Address Date
                </label>
                <div className="col-sm-12">
                  <input
                    onChange={(event) => {
                      setState({
                        ...state,
                        proofOfAddressDate: event.target.value,
                      });
                    }}
                    value={state.proofOfAddressDate}
                    type="date"
                    className="form-control"
                    id="proofOfAddressDate"
                  />
                </div>
              </div>
            </div>
            <div className="form-group  mt-4">
              <label htmlFor="title" className=" ila-form-label text-muted">
                Occupation <span className="text-danger ms-1">*</span>
              </label>
              <div>
                {occupationOptions && occupations && (
                  <CreatableSelect
                    placeholder="Select..."
                    isClearable={true}
                    onChange={(val) => {
                      setAdditionalInfo({
                        ...additionalInfo,
                        occupationId: val.value,
                      });
                    }}
                    // value={{
                    //   label: occupationHashMap.get(additionalInfo.occupationId),
                    //   value: additionalInfo.occupationId,
                    // }}
                    options={occupationOptions}
                  />
                )}
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="employerName"
                className="col-sm-12 col-form-label text-muted mb-0 pb-0"
              >
                Employer Name <span className="text-danger ms-1">*</span>
              </label>
              <div className="col-sm-12">
                <input
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      employerName: event.target.value,
                    });
                  }}
                  value={additionalInfo.employerName}
                  type="text"
                  className="form-control"
                  id="employerName"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="employerAddress"
                className="col-sm-12 col-form-label text-muted mb-0 pb-0"
              >
                Employer Address <span className="text-danger ms-1">*</span>
              </label>
              <div className="col-sm-12">
                <input
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      employerAddress: event.target.value,
                    });
                  }}
                  value={additionalInfo.employerAddress}
                  type="text"
                  className="form-control"
                  id="employerAddress"
                />
              </div>
            </div>
            <div className="mb-3 row">
              <label
                htmlFor="exactDuties"
                className="col-sm-12 col-form-label text-muted mb-0 pb-0"
              >
                Exact Duties <span className="text-danger ms-1">*</span>
              </label>
              <div className="col-sm-12">
                <input
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      exactDuties: event.target.value,
                    });
                  }}
                  value={additionalInfo.exactDuties}
                  type="text"
                  className="form-control"
                  id="exactDuties"
                />
              </div>
            </div>
            {/* <div className="mt-4">
              <label htmlFor="country" className="ila-form-label text-muted">
                Occupation<span className="text-danger ms-1">*</span>
              </label>
              <div>
                <select
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      occupationId: event.target.value,
                    });
                  }}
                  value={additionalInfo.occupationId}
                  className={`form-select`}
                >
                  <option value="">Select...</option>
                  {occupations.map((occupation) => (
                    <option value={occupation.id}>{occupation.title}</option>
                  ))}
                </select>
              </div>
            </div> */}
            <div className="mt-4">
              <label htmlFor="country" className="ila-form-label text-muted">
                Salary Range<span className="text-danger ms-1">*</span>
              </label>
              <div>
                <select
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      salaryrangeId: event.target.value,
                    });
                  }}
                  value={additionalInfo.salaryrangeId}
                  className={`form-select`}
                >
                  <option value="">Select...</option>
                  {salaryRanges.map((salaryRange) => (
                    <option value={salaryRange.id} key={salaryRange.id}>
                      {salaryRange.range}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="country" className="ila-form-label text-muted">
                Highest Educational Qualification
                <span className="text-danger ms-1">*</span>
              </label>
              <div>
                <select
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      educationId: event.target.value,
                    });
                  }}
                  value={additionalInfo.educationId}
                  className={`form-select`}
                >
                  <option value="">Select...</option>
                  {educationalQualifications.map((educationalQualification) => (
                    <option
                      value={educationalQualification.id}
                      key={educationalQualification.id}
                    >
                      {educationalQualification.qualification}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mt-4">
              <label htmlFor="lastname" className="ila-form-label text-muted">
                Awarding Institution for Qualification{" "}
              </label>
              <div>
                <input
                  value={additionalInfo.awardingInstitution}
                  onChange={(event) => {
                    setAdditionalInfo({
                      ...additionalInfo,
                      awardingInstitution: event.target.value,
                    });
                  }}
                  type="text"
                  className="form-control"
                  id="awarding institution"
                />
              </div>
            </div>{" "}
            <div className="mt-4">
              <label className="ila-form-label text-muted">
                Street 1<span className="text-danger ms-1">*</span>
              </label>
              <input
                type="text"
                placeholder="eg. 15, Flamingo lane"
                onChange={(event) => {
                  setState({
                    ...state,
                    streetOne: event.target.value,
                  });
                }}
                className="form-control"
              />
            </div>
            <div className="mt-4">
              <label className="ila-form-label text-muted">Street 2</label>
              <input
                type="text"
                placeholder="eg. Royal Road"
                onChange={(event) => {
                  setState({
                    ...state,
                    streetTwo: event.target.value,
                  });
                }}
                className="form-control"
              />
            </div>
            <div className="mt-4">
              <label className="ila-form-label text-muted">
                City/Town<span className="text-danger ms-1">*</span>
              </label>
              <input
                type="text"
                placeholder="eg. Curepipe"
                onChange={(event) => {
                  setState({
                    ...state,
                    cityTown: event.target.value,
                  });
                }}
                className="form-control"
              />
            </div>
            <div className="mt-4">
              <label className="ila-form-label text-muted">Post Code</label>
              <input
                type="text"
                placeholder="eg. 52605"
                onChange={(event) => {
                  setState({
                    ...state,
                    postCode: event.target.value,
                  });
                }}
                className="form-control"
              />
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="country" className=" ila-form-label text-muted">
                Country<span className="text-danger ms-1">*</span>
              </label>
              <div>
                <select
                  onChange={(event) => {
                    setState({
                      ...state,
                      country: event.target.value,
                    });
                  }}
                  value={state.country}
                  className={`form-select`}
                >
                  <option value="">Select...</option>
                  {countries.map((country, index) => (
                    <option value={country.name} key={index}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="form-group row mt-4">
              <label htmlFor="country" className=" ila-form-label text-muted">
                Marital Status<span className="text-danger ms-1">*</span>
              </label>
              <div>
                <select
                  onChange={(event) => {
                    setState({
                      ...state,
                      maritalStatus: event.target.value,
                    });
                  }}
                  value={state.maritalStatus}
                  className={`form-select`}
                >
                  <option value="">Select...</option>
                  <option value="single">Single</option>
                  <option value="married">Married</option>
                  <option value="divorced">Divorced</option>
                  <option value="widow-er">Widow/Widower</option>
                </select>
              </div>
            </div>
          </form>

          <div className="form-check form-check-flat form-check-primary mb-3 mt-3">
            <label className="form-check-label">
              <input
                onClick={() => {
                  setPhysicalDeliveryConsent(!physicalDeliveryConsent);
                }}
                checked={physicalDeliveryConsent}
                type="checkbox"
                style={{
                  borderColor: "red"
                }}
                className="form-check-input border-1 border-dark"
              />
              By checking this box, you confirm that you want to receive
              physical policy documents
              <span className="text-danger ms-1">*</span>
            </label>
          </div>
          <div className="form-check form-check-flat form-check-primary mb-3 mt-3">
            <label className="form-check-label">
              <input
                onClick={() => {
                  setState({
                    ...state,
                    workerVerificationConsent: !state.workerVerificationConsent,
                  });
                }}
                checked={state.workerVerificationConsent}
                type="checkbox"
                className="form-check-input border-1"
              />
              By checking this box, I confirm that I have sighted the original
              documents.<span className="text-danger ms-1">*</span>
            </label>
          </div>

          <div className="form-group row mb-3">
            <label htmlFor="join_loan" className="col-sm-12  col-form-label">
              Do you consent to receiving promotional emails from ILA.
              <span className="text-danger ms-1">*</span>
            </label>
            <div className="col-sm-8 ">
              <div
                className="form-check "
                onClick={(event) => {
                  setState({
                    ...state,
                    receiveAds: false,
                  });
                }}
              >
                <input
                  readOnly
                  className="form-check-input"
                  type="radio"
                  checked={state.receiveAds === false}
                />
                <label className="form-check-label">No</label>
              </div>
              <div
                className="form-check "
                onClick={() => {
                  setState({
                    ...state,
                    receiveAds: true,
                  });
                }}
              >
                <input
                  readOnly
                  className="form-check-input"
                  type="radio"
                  checked={state.receiveAds === true}
                />
                <label className="form-check-label">Yes</label>
              </div>
            </div>
          </div>

          <div>
            <h5 className="mt-5">
              PEP (Politically Exposed Persons) Questions
            </h5>
            {pepQuestions && (
              <>
                {pepQuestions.map((item, index) => {
                  return (
                    // <>
                    <div className="form-group row mb-3" key={index}>
                      <label
                        htmlFor="join_loan"
                        className="col-sm-12  col-form-label"
                      >
                        {item.content}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="col-sm-8 ">
                        <div
                          className="form-check "
                          onClick={() => {
                            // if (declarationsForLifeNotSaved) {
                            let updatedPepQuestions = [...pepQuestions];
                            updatedPepQuestions[index].hasAccepted = false;
                            setPepQuestions(updatedPepQuestions);
                            // }
                          }}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={item.hasAccepted === false}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        <div
                          className="form-check "
                          onClick={() => {
                            // if (declarationsForLifeNotSaved) {
                            let updatedPepQuestions = [...pepQuestions];
                            updatedPepQuestions[index].hasAccepted = true;
                            setPepQuestions(updatedPepQuestions);
                            // }
                          }}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={item.hasAccepted}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                    </div>
                    // </>
                  );
                })}
              </>
            )}
          </div>
          <div>
            <h5 className="mt-5">
              FATCA (Foreign Account Tax Compliance Act) Questions
            </h5>
            {fatcaQuestions && (
              <>
                {fatcaQuestions.map((item, index) => {
                  return (
                    // <>
                    <div className="form-group row mb-3" key={index}>
                      <label className="col-sm-12  col-form-label">
                        {item.content}
                        <span className="text-danger ms-1">*</span>
                      </label>
                      <div className="col-sm-8 ">
                        <div
                          className="form-check "
                          onClick={() => {
                            // if (declarationsForLifeNotSaved) {
                            let updatedFatcaQuestions = [...fatcaQuestions];
                            updatedFatcaQuestions[index].hasAccepted = false;
                            setFatcaQuestions(updatedFatcaQuestions);
                            // }
                          }}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={item.hasAccepted === false}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                        <div
                          className="form-check "
                          onClick={() => {
                            // if (declarationsForLifeNotSaved) {
                            let updatedFatcaQuestions = [...fatcaQuestions];
                            updatedFatcaQuestions[index].hasAccepted = true;
                            setFatcaQuestions(updatedFatcaQuestions);
                            // }
                          }}
                        >
                          <input
                            readOnly
                            className="form-check-input"
                            type="radio"
                            checked={item.hasAccepted}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                      </div>
                    </div>
                    // </>
                  );
                })}
              </>
            )}
          </div>

          <button
            className="btn ila-primary-btn mt-5 mb-5"
            onClick={() => {
              if (!state.workerVerificationConsent) {
                toast.error("Please check consent box before you proceed");
              } else if (!state.streetOne) {
                toast.error("Please fill street 1 address field");
              } else if (!state.cityTown) {
                toast.error("Please fill City/Town address field");
              } else if (!additionalInfo.occupationId) {
                toast.error("Please select occupation");
              } else if (!additionalInfo.salaryrangeId) {
                toast.error("Please select salary range");
              } else if (!additionalInfo.educationId) {
                toast.error("Please select educational qualification");
              } else if (!state.maritalStatus) {
                toast.error("Please select marital status");
              } else if (!additionalInfo.employerName) {
                toast.error("Please fill employer name");
              } else if (!additionalInfo.employerAddress) {
                toast.error("Please fill employer address");
              } else if (!additionalInfo.exactDuties) {
                toast.error("Please fill exact duties");
              } else {
                submitFiles();
              }
            }}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm me-2"></span>
            )}
            Submit
          </button>
        </div>
      )}
      {authorized === undefined && (
        <div>
          <div className="text-center">
            <span className="spinner-border  mt-5"></span>
          </div>
          <div className="text-center">Please wait ...</div>
        </div>
      )}
      {authorized === false && (
        <div className="container">
          <div
            style={{ marginTop: 50 }}
            className="alert alert-danger"
            role="alert"
          >
            You are Unauthorized to modify this quotation
          </div>
        </div>
      )}
      <SweetAlert2 {...swalProps}></SweetAlert2>
    </>
  );
};

export default ClientKyc;
